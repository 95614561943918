<template>
  <div class="jmsetting">
    <NavBar name="优惠活动设置" />
    <div class="formline">
      <el-form :inline="true">
        <el-form-item>
          <el-select
            placeholder="请选择状态"
            clearable
            v-model="searchForm.is_agree"
          >
            <el-option label="未开始" :value="40"></el-option>
            <el-option label="进行中" :value="50"></el-option>
            <el-option label="已结束" :value="60"></el-option>
            <el-option label="待审批" :value="10"></el-option>
            <el-option label="拒绝" :value="30"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="time"
            class="offset"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd"
            type="daterange"
            @change="timeSet"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" @click="getList">
            搜索
            <template slot="preImage">
              <img src="../../../unit/img/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" @click="getMerchantsList">
            新增
            <template slot="preImage">
              <img src="../../../unit/img/zj.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
    </div>
    <div class="pub-table">
      <el-table
        :data="tableData"
        v-loading="loading"
        :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :loading="loading"
      >
        <el-table-column label="提交人" prop="worker_name"></el-table-column>
        <el-table-column label="提交时间" prop="create_at"></el-table-column>
        <el-table-column
          label="优惠折扣"
          prop="activity_discount"
        ></el-table-column>
        <el-table-column
          label="活动开始时间"
          prop="coupon_start_time"
        ></el-table-column>
        <el-table-column
          label="活动结束时间"
          prop="coupon_end_time"
        ></el-table-column>
        <el-table-column label="参与活动费用" prop="coupon_reason">
          <template #default="{ row }">
            {{ getCouponReason(row) }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="{ row }">
            <span v-if="row.is_agree == 10">待审核</span>
            <span v-if="row.is_agree == 20">同意</span>
            <span v-if="row.is_agree == 30">拒绝</span>
            <span v-if="row.is_agree == 50">进行中</span>
            <span v-if="row.is_agree == 60">已结束</span>
          </template>
        </el-table-column>
        <el-table-column label="审批人" prop="agree_worker_name">
          <template #default="{ row }">
            <span v-if="row.is_agree == 10">/</span>
            <span v-else>{{ row.agree_worker_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="审批时间" width="220px" prop="update_time">
          <template #default="{ row }">
            <span v-if="row.is_agree == 10">/</span>
            <span v-else>{{ row.agree_at }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="agree_remark"></el-table-column>
        <el-table-column label="操作">
          <template #default="{ row }">
            <div class="slot-box">
              <pop
                tips="审批"
                @myclick="handlesh(row)"
                popRight
                v-if="row.is_agree == 10"
              >
                <img src="../../../unit/img/sh.png" class="icon" alt="" />
              </pop>
              <pop tips="删除" @myclick="del(row)">
                <img src="../../../unit/img/sc.png" class="icon" alt="" />
              </pop>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="apiPage" style="text-align: right">
      <el-pagination
        next-text="下一页"
        prev-text="上一页"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="getList"
      ></el-pagination>
    </div>
    <el-dialog
      title="新增"
      :visible.sync="rentderateVisible"
      @close="diaclose"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="right"
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
      >
        <el-form-item label="活动开始时间:" prop="start_time">
          <el-date-picker
            v-model="form.start_time"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="活动结束时间:" prop="end_time">
          <el-date-picker
            v-model="form.end_time"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="优惠折扣:" prop="discount">
          <el-input
            class="label-width"
            type="number"
            v-model="form.discount"
            placeholder="请输入0-1折扣率"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动图片:">
          <el-upload
            class="avatar-uploader"
            action=""
            :on-change="handlePreview"
            :show-file-list="false"
            accept="image/*"
          >
            <img
              v-if="form.coupon_voucher"
              :src="imgUrl + form.coupon_voucher"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="参与活动费用:" prop="coupon_reason">
          <el-checkbox-group v-model="form.coupon_reason">
            <el-checkbox :label="-1">日常费用</el-checkbox>
            <el-checkbox
              :label="item.id"
              v-for="item in chargingItems"
              :key="item.id"
              >{{ item.contract_fee_name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <MyButton @click="rentderateVisible = false">取消</MyButton>
        <MyButton left @click="add" type="primary">新增</MyButton>
      </template>
    </el-dialog>
    <el-dialog
      title="审批"
      :visible.sync="spvisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form label-width="100px">
        <el-form-item label="备注">
          <el-input
            v-model="spform.agree_remark"
            type="textarea"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="spconfirm(30)">拒绝</el-button>
        <el-button @click="spconfirm(20)" type="primary">同意</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    const setDiscount = (rule, value, fn) => {
      if (!value) return fn(new Error("请输入折扣"));
      if (value > 1 || value < 0) {
        return fn(new Error("折扣范围0~1"));
      }
      return fn();
    };
    return {
      loading: false,
      form: {
        name: "优惠活动",
        expire_type: 20,
        coupon_type: 20,
        start_time: "",
        end_time: "",
        discount: "",
        coupon_voucher: "",
        coupon_reason: [],
        apply_range: "",
      },
      rules: {
        start_time: [
          { required: true, message: "请选择活动开始时间", trigger: "change" },
        ],
        end_time: [
          { required: true, message: "请选择活动结束时间", trigger: "change" },
        ],
        discount: [
          { required: true, message: "请输入优惠折扣", trigger: "blur" },
          { validator: setDiscount },
        ],
        coupon_reason: [
          { required: true, message: "请选择参与活动费用", trigger: "change" },
        ],
      },
      searchForm: {
        coupon_type: 20,
      },
      spvisible: false,
      spform: {
        agree_remark: "",
        expire_type: 20,
        coupon_type: 20,
        name: "优惠活动",
      },
      total: 0,
      time: "",
      rentderateVisible: false,
      merchantsList: [],
      tableData: [],
      date: [],
      imgUrl: process.env.VUE_APP_IMG_URL,
      chargingItems: [],
    };
  },
  created() {
    this.getList();
    this.getChargingItems();
  },
  methods: {
    getChargingItems() {
      this.$request.HttpPost("/contract_fee_setting/listAll").then((res) => {
        this.chargingItems = res.data?.filter((item) => item.type != 20) ?? [];
      });
    },
    spconfirm(type) {
      this.$request
        .HttpPost("/coupon/isAgree", {
          ...this.spform,
          is_agree: type,
        })
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.spvisible = false;
          this.getList();
        });
    },
    getCouponReason(row) {
      if (row.coupon_reason) {
        let arr = row.coupon_reason.split(",");
        let text = [];
        for (let i in arr) {
          if (arr[i] == "-1") {
            text.push("日常费用");
          }
          this.chargingItems.forEach((item) => {
            if (item.id == arr[i]) {
              text.push(item.contract_fee_name);
            }
          });
        }
        return text.join(",");
      }
      return "";
    },
    del(row) {
      this.$myconfirm("此操作将永久删除该活动设置, 是否继续?", "提示").then(
        () => {
          this.$request
            .HttpPost("/coupon/delete", {
              ids: [row.coupon_id],
            })
            .then((res) => {
              this.$common.notifySuccess("删除成功");
              this.getList();
            });
        }
      );
    },
    add() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$request.HttpPost("/coupon/add", this.form).then((res) => {
            this.getList();
            this.rentderateVisible = false;
          });
        }
      });
    },
    handlePreview(file) {
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.$request.HttpPost("/system/upload/upImg", formData).then((res) => {
          this.form.coupon_voucher = res.data.fileInfo.hostUrl;
        });
      }
    },
    getList(page = 1) {
      this.loading = true;
      this.tableData = [];
      this.searchForm.pageNum = page;
      this.$request
        .HttpGet("/coupon/list", this.searchForm)
        .then((res) => {
          this.loading = false;
          if (res.data && res.data.list) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    timeSet(val) {
      this.searchForm.beginTime = val ? val[0] : "";
      this.searchForm.endTime = val ? val[1] : "";
    },
    getMerchantsList() {
      this.rentderateVisible = true;
    },
    diaclose() {
      this.form = {
        start_time: "",
        end_time: "",
        discount: "",
        coupon_voucher: "",
        coupon_reason: [],
        apply_range: "",
        coupon_type: 20,
        name: "优惠活动",
      };
    },
    handlesh(item) {
      this.spform.coupon_id = item.coupon_id;
      this.spvisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.formline {
  margin-top: 20px;
}
.label-width {
  width: 202px;
}
.voucher-img {
  width: 400px;
  height: 400px;
  object-fit: contain;
}
.upload-imgx-box {
  position: relative;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  display: inline-block;
  .upload-img {
    width: 100%;
    height: 100%;
  }
  span {
    position: absolute;
    right: -10px;
    top: -10px;
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid rgb(110, 108, 108);
    border-radius: 50%;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
  }
}
</style>
